import React, { useState, useRef } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { useEffect } from "react";
import Modal from "../Modal2/Modal";
import { useIntl } from "react-intl";

import "./wizard.scss";
import SmartRendering from "../smartRendering/SmartRendering";

function getPositionAtCenter(element) {
  if (!element || typeof element === "undefined") {
    return { x: 0, y: 0 };
  }
  const { top, left, width, height } = element?.getBoundingClientRect();
  return {
    x: left + width / 2,
    y: top + height / 2,
  };
}

function getDistanceBetweenElements(a, b) {
  const aPosition = getPositionAtCenter(a);
  const bPosition = getPositionAtCenter(b);

  return Math.sqrt(
    Math.pow(aPosition.x - bPosition.x, 2) +
      Math.pow(aPosition.y - bPosition.y, 2)
  );
}

const Wizard = React.memo(
  ({
    id,
    className,
    currStep,
    onClick,
    children,
    show,
    onWizardAbort = null,
    errorMessage,
    loading,
    title,
    onReachVerticalEnd,
    forceFullWidth,
  }) => {
    const intl = useIntl();
    const [distance, setDistance] = useState([]);
    const buttomMoreItems = useRef(null);
    const modalRef = useRef(null);

    const ReactChildren = React.Children;

    const wizardSteps = ReactChildren.map(children, (child) => {
      if (child?.type === Wizard.Step) {
        return React.cloneElement(child);
      }
    });
    const items = wizardSteps.map((m) => {
      return {
        passed: m.props.passed,
        title: m.props.title,
        disabled: m.props.disabled
      }
    });

    const wizardFooter = ReactChildren.map(children, (child) => {
      if (child?.type === Wizard.Footer) {
        return React.cloneElement(child);
      }
    });


    useEffect(() => {
      function adjustItemsDistance() {
        const distanceArray = Array(items.length)
          .fill(0)
          .map((m, i) => {
            if (i - 1 >= 0) return [`wizard_item_${i}`, `wizard_item_${i - 1}`];
            else return null;
          })
          .filter((f) => f);

        const individualDistance = distanceArray.map((m) => {
          return getDistanceBetweenElements(
            document.getElementById(m[1]),
            document.getElementById(m[0])
          );
        });

        setDistance(individualDistance);
      }
      if (show) {
        setTimeout(() => {
          adjustItemsDistance();
        }, 100);
        window.addEventListener("resize", adjustItemsDistance, false);
        return () => window.removeEventListener("resize", adjustItemsDistance);
      } else {
        return () => window.removeEventListener("resize", adjustItemsDistance);
      }
    }, [show, items]);

    const handleAchieveVerticalEnd = () => {
      onReachVerticalEnd && onReachVerticalEnd();
    };

    const clickButton = (e) => {
      buttomMoreItems.current.click();
    };
    /** Função para obter o header da etapa do wizard caso exista */
    // const currentWizardHeader =
    // wizardSteps && wizardSteps[currStep] && wizardSteps[currStep]?.props?.children?.length > 0
    //     ? wizardSteps[currStep].props.children.find(
    //         (f) => f.type === Wizard.StepHeader
    //       )
    //     : null;

    /** Função para obter o conteúdo da etapa do wizard caso exista */
    // const currentWizardContent =
    //  wizardSteps && wizardSteps[currStep] && wizardSteps[currStep]?.props?.children?.length > 0
    //     ? wizardSteps[currStep].props.children.find(
    //         (f) => f.type === Wizard.StepContent
    //       )
    //     : wizardSteps[currStep].props.children;

    const renderWizard = (
      <SmartRendering componentWillRender={show}>
        <div
          id={`rc-wizard-ui-modal-${id}`}
          className={`rc-wizard-ui ${className ? className : ""} ${
            show ? "show" : ""
          }`}
          ref={modalRef}
        >
          <Modal
            show={show}
            toggle={loading ? () => {} : onWizardAbort}
            position="center"
            size="md"
            errorMessage={errorMessage}
            onPressEnter={() => {}}
          >
            {title && <Modal.Header>{title}</Modal.Header>}
            <Modal.TopElement>
              <div className={`rc-wizard-nav ${className ? className : ""}`}>
                <ul>
                  {items.map((m, i) => {
                    const width = distance[i];
                    return (
                      <li
                        key={`wizard_item_${i}`}
                        className={classNames({
                          active: currStep === i,
                          passed: currStep > i || m.passed,
                          disabled: m.disabled
                        })}
                        onClick={() => (currStep > i ? onClick(i) : {})}
                      >
                        <span id={`wizard_item_${i}`}>
                          <div
                            className="wizard-nav-bar"
                            style={{ width: width }}
                          ></div>
                          {i + 1}
                        </span>
                        {m.title}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Modal.TopElement>
            {wizardSteps.length > 0
              ? wizardSteps.map((step, i) => {
                  const active = step.props.active;
                  return (
                    <div
                      key={`step_${i}`}
                      className={`wizard-step-content ${
                        show && active ? "active" : ""
                      }`}
                    >
                      {step}
                    </div>
                  );
                })
              : null}
            {/* {currentWizardContent && <div className={`wizard-step-content ${className}`}>{currentWizardContent}</div>} */}
            {onReachVerticalEnd && (
              <button
                ref={buttomMoreItems}
                onClick={handleAchieveVerticalEnd}
                hidden
              />
            )}
            <Modal.Footer>{wizardFooter}</Modal.Footer>
          </Modal>
        </div>
      </SmartRendering>
    );

    return forceFullWidth
      ? ReactDOM.createPortal(
        renderWizard,
          document.getElementById("modal-root")
        )
      : renderWizard;
  }
);

Wizard.Step = ({ children, active }) => children;
// {
//     return (
//         <div
//             className={`wizard-step ${active ? 'active' : ''}`}>
//             {children}
//         </div>
//     )
// }
Wizard.StepHeader = ({ children }) => children;

Wizard.StepContent = ({ children, className = "" }) => children;
{
  /* <div className={`wizard-step-content ${className}`}>
    {children}
</div> */
}

Wizard.Footer = ({ children }) => children;


export default Wizard;
