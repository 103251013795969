import React, { useState, ReactNode, FC, ReactElement } from "react";
import { Heading } from "../typography";
import { Check, Chevron } from "../pureHtmlIcons";
import Loading from '../loading/Loading';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import "./list.scss";

interface ListProps {
    children: ReactNode;
    className?: string;
    isLoadingMore?: boolean;
    collapse?: boolean;
}

interface ListItemProps {
    avatar?: ReactNode;
    title: string;
    subtitle?: any;
    className?: string;
    color?: string;
    onClick?: () => void;
    active?: boolean;
    align?: "left" | "right";
    rightSideComponent?: ReactNode;
    truncateTitle?: number;
    truncateSubtitle?: number;
    showTooltip?: boolean;
    tooltipVisible?: boolean;
    onTooltipToggle?: (visible: boolean) => void;
}

interface ListGroupProps {
    children: ReactNode;
    id?: number | string;
    className?: string;
}

interface ListTitleProps {
    children: ReactNode;
    title?: string;
    onClick?: (args: { index: number, id: any }) => void | null;
}

const List: FC<ListProps> & {
    Group: FC<ListGroupProps>;
    Title: FC<ListTitleProps>;
    Item: FC<ListItemProps>;
} = ({
    children,
    className,
    isLoadingMore = false,
    collapse = false,
}) => {
    const [collapsedGroups, setCollapsedGroups] = useState<number[]>([]);
    const [tooltipVisibleIndexes, setTooltipVisibleIndexes] = useState<{ [groupIndex: number]: number | null }>({});

    const toggleHideGroup = (idx: number) => {
        setCollapsedGroups(prevState => {
            const newState = [...prevState];
            if (newState.includes(idx)) {
                const index = newState.indexOf(idx);
                newState.splice(index, 1);
            } else {
                newState.push(idx);
            }
            return newState;
        });
    };

    const handleClickTitle = (index: number, id: any, onClick?: (args: { index: number, id: any }) => void | null) => {
        if (onClick) {
            onClick({ index, id });
        }

        if (collapse) {
            toggleHideGroup(index);
        }
    };

    const group = React.Children.map(children, (child, groupIndex) => {
        if (React.isValidElement(child) && child.type === List.Group) {
            const titleElements: ReactElement<ListTitleProps>[] = [];
            const itemElements: ReactElement<ListItemProps>[] = [];

            React.Children.forEach(child.props.children, (childElement, itemIndex) => {
                if (React.isValidElement(childElement) && childElement.type === List.Item) {
                    const isTooltipVisible = tooltipVisibleIndexes[groupIndex] === itemIndex;

                    itemElements.push(React.cloneElement(childElement as ReactElement<ListItemProps>, {
                        tooltipVisible: isTooltipVisible,
                        onTooltipToggle: (visible: boolean) => {
                            setTooltipVisibleIndexes(prev => ({
                                ...prev,
                                [groupIndex]: visible ? itemIndex : null,
                            }));
                        }
                    }));
                } else if (React.isValidElement(childElement) && childElement.type === List.Title) {
                    titleElements.push(React.cloneElement(childElement) as ReactElement<ListTitleProps>);
                }
            });

            const id = child.props.id || null;
            const title = titleElements[0];
            const onClick = title?.props.onClick;
            const collapsed = collapse && collapsedGroups.includes(groupIndex);

            return (
                <div className="list-group" key={groupIndex}>
                    {titleElements.length > 0 && (
                        <div
                            className={`list-title ${onClick || collapse ? 'clickable' : ''}`}
                            onClick={() => collapse ? handleClickTitle(groupIndex, id, onClick) : {}}>
                            <Heading type="h6" className="m-1" fw={600}>
                                {title}
                            </Heading>
                            {collapse && (
                                <div className="list-title-icon">
                                    <Chevron direction={collapsed ? "down" : "up"} />
                                </div>
                            )}
                        </div>
                    )}
                    <div className={`list-group-items ${!collapsed ? 'show' : ''}`}>
                        {itemElements}
                    </div>
                </div>
            );
        }

        return null;
    });

    return (
        <div className={`list-ui ${className || ''}`}>
            <div>{group}</div>
            {isLoadingMore && (
                <div className="list-ui-loading">
                    <Loading />
                </div>
            )}
        </div>
    );
};

List.Group = ({ children }) => children;

List.Title = ({ children }) => children;

List.Item = ({
    avatar,
    title,
    subtitle,
    className,
    color,
    onClick,
    active = false,
    align = "left",
    rightSideComponent,
    truncateTitle = 999,
    truncateSubtitle = 999,
    showTooltip = false,
    tooltipVisible = false,
    onTooltipToggle,
}) => {
    return (
        <Tippy 
            content={subtitle} 
            visible={tooltipVisible}
            placement={'top'}
            className="primary-tooltip"
        >
        <div
            onClick={onClick ? onClick : () => {}}
            className={`list-item-ui ${className || ''} ${color ? `ui-${color}` : ''} ${active ? 'active' : ''} align-${align}`}
            onMouseEnter={() => showTooltip && onTooltipToggle && onTooltipToggle(true)}
            onMouseLeave={() => showTooltip && onTooltipToggle && onTooltipToggle(false)}
        >
            <div className="list-item-content">
                <div className="list-item-content-contact">
                    {avatar}
                    <div className="list-item-contact-description">
                        <div className="texts">
                            <h6 style={{ WebkitLineClamp: truncateTitle }}>{title}</h6>
                            <small style={{ WebkitLineClamp: truncateSubtitle }}>
                                    <span>{subtitle}</span>
                            </small>
                        </div>
                    </div>
                </div>
            </div>
            {rightSideComponent && <div className="list-item-right-side">{rightSideComponent}</div>}
        </div>
        </Tippy>
    );
};

List.displayName = "List";

export default List;
